h1 {
  color: var(--blue);
  font-weight: 600;
  margin-bottom: 0.8rem;
}

h2 {
  color: var(--blue);
  font-weight: 400;
  font-size: 1.1rem;
  text-align: center;
}

a {
  text-decoration: none;
  color: var(--orange);
}

p {
  color: var(--blue);
  font-size: 0.8rem;
  font-weight: 500;
}

p span a {
  font-weight: 500;
}

.button-link {
  background-color: var(--orange);
  color: white;
  border-radius: 4px;
  padding: 0.5rem;
  width: 25rem;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.button-link:hover {
  background-color: var(--orange-light);
}

.app {
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.app:before {
  content: "";
  background-image: url("./assets/images/grey-daruma.svg");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: 30% -5%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.3;
  z-index: -1;
}

.app > div {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.app img {
  width: 25rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .app {
    padding: 0.5rem;
  }

  .app img {
    width: 80%;
    margin-bottom: 2rem;
  }

  .app > div {
    width: 100%;
  }

  .button-link {
    width: 80%;
  }
}
